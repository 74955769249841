import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../sass/main.scss"
import { Layout, SEO, HeadSection, Image } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import $ from "jquery"
import Slugify from "slugify"

const ClientItems = () => {
  const clients: any = useStaticQuery(graphql`
    query {
      allClient(sort: { order: DESC, fields: order }) {
        edges {
          node {
            id
            title
            logo
            industry
            testimonial_commenter_position
            testimonial_commenter
            testimonial_comment
            featured_image
          }
        }
      }
    }
  `)
  return (
    <Row>
      {clients.allClient.edges.map((client: any, index: number) => (
        <Col xs={3} sm={4} md={5} lg={4} xl={4} className="client" key={index}>
          <div className="st-block">
            <Image
              path={client.node.featured_image}
              isSquare={true}
              alt={"Client | " + client.node.title}
            />
            <div className="client__content">
              <Row className="h-100">
                <Col xl="12" className="mt-auto">
                  <span className="client__category text-white">
                    {client.node.industry}
                  </span>
                  <h3 className="text-white">{client.node.title}</h3>
                  <div className="client__comment text-white">
                    <p>{client.node.testimonial_comment}</p>
                    <p className="font-weight-medium client__commenter">
                      {client.node.testimonial_commenter}
                      {client.node.testimonial_commenter_position
                        ? ", " + client.node.testimonial_commenter_position
                        : ""}
                    </p>
                    <Link
                      to={
                        "/clients/" + Slugify(client.node.title.toLowerCase())
                      }
                      title={client.node.title}
                      className="learn-more"
                    >
                      Read more
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}
const ClientsPage = () => {
  useEffect(() => {
    $(".client").hover(
      function() {
        $(this).addClass("show")
      },
      function() {
        $(this).removeClass("show")
      },
    )
  })
  const headTitle = (): string => {
    return "Clients"
  }
  const headSubTitle = (): string => {
    return `We build enterprise AI solutions that have a positive
    impact on our environment, our clients, their customers and employees.`
  }
  return (
    <Layout step={2}>
      <SEO
        title="Our clients | Satalia | Enterprise AI systems"
        description="We help our clients have a positive impact on their costs, their customers and the environment."
        twitterTitle="Our clients | Satalia | Enterprise AI systems"
        twitterDescription="We help our clients have a positive impact on their costs, their customers and the environment."
        openGraphTitle="Our clients | Satalia | Enterprise AI systems"
        openGraphDescription="We help our clients become more efficient."
      />
      <Container>
        <section id="clients" className="mb-25">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
          ></HeadSection>
        </section>
        <section id="clients-list" className="pb-75">
          {ClientItems()}
        </section>
      </Container>
    </Layout>
  )
}

export default ClientsPage
